import moment from 'moment';

function datetime(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY à HH:mm');
  }
  return '';
}

function date(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
  return '';
}

function time(value) {
  if (value) {
    return moment(value).format('HH:mm:ss');
  }
  return '';
}

export { datetime, date, time };
