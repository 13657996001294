<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
      <div class="container-fluid">
        <router-link class="nav-link logo" to="/">
          <span class="navbar-brand mb-0 h1">
            <!-- <div class="logo"></div> -->
            <!-- <img src="/logo_edelpay.png" class="logo "/> -->
            <!-- {{ title }} -->
          </span>
        </router-link>
        <div class="d-flex align-items-center">
          <div v-if="merchants && merchants.length > 1">
            <div
              class="spinner-border text-primary mr-2"
              role="status"
              v-if="isMerchantLoading"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <merchant-selector class="d-inline-block" v-if="merchants" />
          </div>
          <span class="navbar-text" v-else-if="merchant">{{
            merchant.name
          }}</span>

          <user-menu />
        </div>
      </div>
    </nav>

    <div class="container mb-5" v-if="merchant && !isMerchantLoading">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <router-view />
        </div>
      </div>
    </div>
    <notifications />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Notifications from './components/Notifications.vue';
import MerchantSelector from './components/MerchantSelector.vue';
import UserMenu from './components/user/UserMenu.vue';

export default {
  components: {
    Notifications,
    MerchantSelector,
    UserMenu,
  },

  computed: {
    ...mapState(['merchant', 'merchants']),
    ...mapGetters(['isMerchantLoading']),
    title() {
      return process.env.VUE_APP_NAME;
    },
  },
  methods: {
    ...mapActions('user', ['loadUserAndCredentials']),
  },
  created() {
    this.loadUserAndCredentials(this.$router);
  },
};
</script>

<style lang="scss">
@import "./styles/settings";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.logo {
  background: url("logo_edelpay.png") no-repeat;

  width: 120px;
  height: 50px;
  background-size: contain;
  position: relative;
  left: 10px;
}

.navbar {
  border-top: solid 3px $primary;
  background-color: white;
  box-shadow: 0 0 10px rgba($black, 0.05);
  margin-bottom: 1em;

  .spinner-border {
    font-size: 0.5em;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.navbar-light .navbar-brand {
  color: $primary !important;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  span {
    color: $orange;
  }
}

.nav-item {
  a.nav-link {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.7em;
    color: $secondary;

    &.router-link-exact-active {
      color: $primary;
    }
  }
}
</style>
