<template>
  <div class="mt-5">
    <div class="page-headings">
      <h1 class="page-title">Envoi d'une nouvelle demande de paiement</h1>
      <transaction-status
        :status="transaction.status"
        :statusDates="transaction.status_dates"
      />
    </div>

    <div v-if="merchant.id"
    class="row mt-5">
      <div class="col-sm-6">
        <form :class="{'is-loading': isSaveLoading || isGetLoading}">
          <div class="form-fields">
            <div v-if="editMode" class="form-group">
              <label for="seller">Vendeur précédent</label>
                <input data-test="previousSeller"
                  name="previousSeller"
                  type="text"
                  class="form-control"
                  :value="previousSeller"
                  readonly
                />
            </div>
            <div class="form-group required">
              <label class="mb-0" for="seller">Vendeur</label>
                <small class="small-text mt-0 mb-2">
                  Merci d'indiquer votre prénom
                </small>
                <input
                  @input="onChange($event)"
                  @blur="onBlur($event)"
                  @focus="onFocus($event.target.name)"
                  required
                  name="seller" data-test="seller"
                  type="text"
                  class="form-control"
                  ref="sellerInput"
                  :value="transaction.seller"
                  :class="getInputError('seller')"
                  :readonly="!transaction.isUpdatable "
                />
                <small class="small-text" :class="getInputError('seller')">
                  <span
                    v-if="errors.seller.valid === false && errors.seller.error">
                    {{ errors.seller.error }}
                  </span>
                </small>
            </div>
            <div class="form-group required">
              <label class="mb-0" for="email">Email du client</label>
                <small class="small-text mt-0 mb-2">
                  Vérifiez bien l'orthographe de l'email
                </small>
                <input
                  @input="onChange($event)"
                  @blur="onBlur($event)"
                  @focus="onFocus($event.target.name)"
                  :value="transaction.email"
                  :class="getInputError('email')"
                  :readonly="!transaction.isUpdatable "
                  required
                  name="email"  data-test="email"
                  type="email"
                  class="form-control"
                />
                <small class="small-text" :class="getInputError('email')">
                  <span
                    v-if="errors.email.valid === false && errors.email.error">
                    {{ errors.email.error }}
                  </span>
                </small>
            </div>
            <div class="form-group required">
              <label class="mb-0" for="amount">Montant (€)</label>
              <small class="small-text mt-0 mb-2">
                Indiquez le montant avec ou sans virgule
              </small>
              <currency-input
                class="form-control amount"
                name="amount"  data-test="amount"
                @input="onChange($event)"
                @blur="onBlur($event)"
                @focus="onFocus($event.target.name)"
                :class="getInputError('amount')"
                :readonly="!transaction.isUpdatable "
                :value="transaction.amount"
                required
                 />
              <small class="small-text" :class="getInputError('amount')">
                <span
                  v-if="errors.amount.valid === false && errors.amount.error">
                  {{ errors.amount.error }}
                </span>
              </small>
            </div>
            <div class="form-group">
              <label class="mb-0" for="ref">Référence</label>
              <small class="small-text mt-0 mb-2">
                Vous permet de retrouver cette référence de commande dans ePaywel.
                Vous disposez de 32 caractères maximum
              </small>
              <input
                @input="onChange($event)"
                @blur="onBlur($event)"
                @focus="onFocus($event.target.name)"
                :class="getInputError('ref')"
                :value="transaction.ref"
                :readonly="!transaction.isUpdatable "
                name="ref"  data-test="ref"
                type="text"
                class="form-control"
              />

              <small class="small-text" :class="getInputError('ref')">
                <span
                  v-if="errors.ref.valid === false && errors.ref.error">
                  {{ errors.ref.error }}
                </span>
                </small>
            </div>
            <div class="form-group required">
              <label class="mb-0" for="ref">Moyens de paiement</label>
              <small class="small-text mt-0 mb-2">
                Sélectionnez le ou les moyens de paiement que vous souhaitez proposer
                 à votre client pour le règlement de cette demande de paiement
              </small>
              <div class="form-check mr-3"
               v-for="c in contracts" :key="c.name">
                  <input
                    type="checkbox"
                    :name="'contract-'+c.name"  :data-test="'i-contract-'+c.name"
                     class="form-check-input"
                    :id="'i-contract-'+c.name"
                    :checked="c.selected"
                    :disabled="c.disabled"
                    @input="onChangeContract(c, $event)">
                  <label class="form-check-label mb-0" :for="'i-contract-'+c.name">
                   {{c.name}}
                  </label>
                </div>

              <small class="small-text" :class="getInputError('contracts')">
                <span
                  v-if="errors.contracts.valid === false && errors.contracts.error">
                  {{ errors.contracts.error }}
                </span>
                </small>
            </div>
            <div class="form-group required">
                <label class="mb-0" for="capture">
                  Encaissement automatique
                </label>
                <small class="small-text mt-0 mb-2">
                  Indiquez si vous souhaitez que la carte du client soit débitée
                  automatiquement ou si vous souhaitez déclencher le débit manuellement
                  (validation manuelle après la réservation auprès du fournisseur)
                </small>
              <div class="d-flex justify-content-start">
                <div class="form-check mr-3">
                  <div v-for="p in paiementList" :key="p.id" >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="capture"  data-test="p.id"
                    :id="p.id"
                    :value="p.value"
                    :checked="transaction.capture === p.value"
                    :disabled="!transaction.isUpdatable "
                    @input="onChange($event)">
                  <label class="form-check-label mb-0" :for="p.id">
                    {{p.label}}
                  </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="form-group">
              <label class="mb-0" for="description">Description</label>
              <small class="small-text mt-0 mb-2">
                Indiquez de préférence un descriptif, par exemple une précision
                sur l'offre, une information relative au règlement.
              </small>
              <textarea
                @input="onChange($event)"
                @blur="onBlur($event)"
                @focus="onFocus($event.target.name)"
                :class="getInputError('description')"
                :value="transaction.description"
                :readonly="!transaction.isUpdatable"
                name="description"  data-test="description"
                type="text"
                class="form-control"></textarea>
              <small class="small-text" :class="getInputError('description')">
                <span
                  v-if="errors.description.valid === false && errors.description.error">
                  {{ errors.description.error }}
                </span>
              </small>
            </div>
            <div class="form-group">
              <label class="mb-0" for="message">Message</label>
              <small class="small-text mt-0 mb-2">
                Un message d'invitation vous est proposé par défaut.
                Vous pouvez le personnaliser comme vous le souhaitez.
              </small>
                <rich-text-editor
                @input="onChange({target: {value: $event,  name: 'message'}})"
                :class="getInputError('message')"
                v-model="transaction.message"
                :readonly="!transaction.isUpdatable"
                name="message"  data-test="message"
                type="text"
                />

              <small class="small-text" :class="getInputError('message')">
                <span
                  v-if="errors.message.valid === false && errors.message.error">
                  {{ errors.message.error }}
                </span>
              </small>
            </div>
            <div class="form-group" v-if="expirationsPresets">
              <label class="mb-0" for="message">Durée de validité du lien</label>
              <small class="small-text mt-0 mb-2">
                Indiquez la durée durant laquelle le lien le bouton de paiement sera valide,
                au delà de cette période, la demande devra être renvoyée.
              </small>
              <select
                @input="onChange($event)"
                @blur="onBlur($event)"
                @focus="onFocus($event.target.name)"
                :class="getInputError('expires_in')"
                :value="transaction.expires_in"
                :readonly="!transaction.isUpdatable"
                v-if="expirationsPresets.length > 0"
                name="expires_in"  data-test="expires_in"
                class="form-select">
                <option
                  v-for="(v, i) in expirationsPresets" :key="i"
                  :value="v.value"
                >
                {{v.label}}
                </option>
              </select>
              <small class="small-text" :class="getInputError('expires_in')">
                <span
                  v-if="errors.expires_in.valid === false && errors.expires_in.error">
                  {{ errors.expires_in.error }}
                </span>
              </small>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-6">
        <div class="sticky-top">
          <preview  v-if="transaction.isUpdatable" />
          <receipt :transaction="transaction"
            v-else-if="transaction.isTerminated && transaction.status === 'PAYMENT_COMPLETE'" />
        </div>
      </div>
      <div class="form-actions" v-if="transaction">
        <button class="btn btn-back mx-1" @click.prevent="backToHome"
         data-test="b_back" >Retour</button>
        <button-confirm
          v-if="transaction.status === 'PAYMENT_ACCEPTED'"
          @click.prevent="onClickValidate"
          :disabled="isCaptureLoading || !permissions['EPM.pr.write']"
          class="btn btn-success">
          <span
            v-if="isCaptureLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
          Valider le paiement
        </button-confirm>
        <div
          v-else-if="transaction.isUpdatable && !isGetLoading"
          @mouseenter="displayFormHelper"
          @mouseleave="displayFormHelper(false)"
           @focusin="displayFormHelper"
          @focusout="displayFormHelper(false)"
          class="btn-tooltip-wrapper">

          <button-confirm class="btn mx-1"
            :class="{'btn-light': !disabledSaveButton, 'btn-outline-light': disabledSaveButton}"
            :disabled="disabledSaveButton || isSaveLoading"
            :disableConfirm="!transaction.status"
            @click.prevent="save">
            <span
              v-if="isSaveLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            <span v-else-if="!transaction.status">Envoyer la demande paiement</span>
            <span v-else>Renvoyer la demande paiement</span>
          </button-confirm>
          <div v-show="formHelper" class="form-errors-info bg-warning"  data-test="listErrors" >
            <h5 v-if="listErrors.length > 0"
            class="form-helper-title">Veuillez corriger les champs suivants:</h5>
            <ul>
              <li v-for="err in listErrors" :key="err">
                {{ err }}
              </li>
            </ul>
            <h5 v-if="!permissions['EPM.pr.write']"  class="form-helper-title">
              Votre rôle ne vous permet pas de cliquer sur ce bouton.
            </h5>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

/* eslint-disable camelcase */

import {
  mapMutations, mapActions, mapGetters, mapState,
} from 'vuex';

import validate from '../lib/validations';
import Preview from '../components/TransactionFormPreview.vue';
import TransactionStatus from '../components/TransactionStatus.vue';
import Receipt from '../components/TransactionReceipt.vue';
import CurrencyInput from '../components/CurrencyInput.vue';
import ButtonConfirm from '../components/ButtonConfirm.vue';
import RichTextEditor from '../components/lib/RichTextEditor.vue';

function getFormValue(target) {
  const {
    value, name, type, _value,
  } = target;
  // La value ne suis pas au bon format :/
  return type === 'radio' || name === 'amount' ? _value : value;
}

const DEBIT_INSTANT = {
  label: 'Débit immédiat',
  id: 'capture1',
  value: true,
};
const DEBIT_AFTER_VAL = {
  label: 'Débit après validation manuelle',
  id: 'capture2',
  value: false,
};

export default {
  components: {
    Preview,
    Receipt,
    TransactionStatus,
    CurrencyInput,
    ButtonConfirm,
    RichTextEditor,
  },
  data() {
    return {
      previousSeller: '',
      errors: {
        seller: { valid: null },
        email: { valid: null },
        amount: { valid: null },
        ref: { valid: null },
        contracts: { valid: null },
        capture: { valid: null },
        description: { valid: null },
        message: { valid: null },
        expires_in: { valid: null },
      },
      formHelper: false,
      editMode: false,
      validationMap: {
        seller: { validable: false, required: true, label: 'Vendeur' },
        email: { validable: false, required: true, label: 'Email du client' },
        amount: { validable: false, required: true, label: 'Montant' },
        ref: { validable: false, required: false, label: 'Référence' },
        contracts: { validable: false, required: true, label: 'Moyen de paiement' },
        capture: { validable: false, required: true, label: 'Envoi en banque automatique' },
        description: { validable: false, required: false, label: 'Descripton' },
        message: { validable: false, required: false, label: 'Message' },
        expires_in: { validable: false, required: false, label: 'Expiration' },
      },
      paiementList: [DEBIT_INSTANT, DEBIT_AFTER_VAL],
    };
  },
  methods: {
    ...mapMutations([
      'addTransaction',
    ]),
    ...mapActions([
      'captureTransaction',
      'newTransaction',
      'loadTransaction',
      'setToast',
      'saveTransaction',
      'setTransaction',
      'updateTransaction',
      'deleteTransaction',
    ]),
    async save() {
      await this.saveTransaction(this.editMode);
      this.$router.push({ name: 'transactions' });
    },
    async deleteTransaction(id) {
      await this.deleteTransaction(id);
      this.$router.push({ name: 'transactions' });
    },

    async onChangeContract(c, e) {
      // eslint-disable-next-line no-param-reassign
      c.origin.selected = e.target.checked;
      this.onChange(e);
      this.paiementList = await this.paiementListBuild();
    },
    async paiementListBuild() {
      const result = [];
      const selectedContracts = (this.transaction.contracts || []).filter((c) => c.selected);
      const modes = selectedContracts.reduce((acc, e) => {
        acc.paymentCapture = acc.paymentCapture && e.paymentCapture;
        acc.paymentDirect = acc.paymentDirect && e.paymentDirect;
        return acc;
      }, {
        paymentDirect: true,
        paymentCapture: true,
      });
      if (modes.paymentDirect) {
        result.push(
          DEBIT_INSTANT,
        );
      } else if (this.transaction.capture) {
        const tr = { ...this.transaction, capture: false };
        await this.setTransaction(tr);
        const name = 'capture';
        this.validationMap[name].validable = true;
        this.errors = { ...this.errors, [name]: true };
      }
      if (modes.paymentCapture) {
        result.push(
          DEBIT_AFTER_VAL,
        );
      } else if (!this.transaction.capture) {
        const tr = { ...this.transaction, capture: true };
        await this.setTransaction(tr);
        const name = 'capture';
        this.validationMap[name].validable = true;
        this.errors = { ...this.errors, [name]: true };
      }
      return result;
    },
    async onChange(e) {
      const {
        name, required,
      } = e.target;
      if (!name) {
        return;
      }

      if (name.startsWith('contract')) {
        this.validationMap.contracts.validable = true;
        let contracts;
        if (this.transaction.contracts.filter((c) => c.selected).length === 0) {
          contracts = { valid: false, error: 'Veuillez sélectionner au moins un moyen de paiement' };
        } else {
          contracts = { valid: true };
        }
        this.errors = { ...this.errors, contracts };
      } else {
        const value = getFormValue(e.target);
        if (this.validationMap[name]) {
          this.validationMap[name].validable = true;
          this.errors = { ...this.errors, [name]: validate(value, name, required) };
        }

        const tr = { ...this.transaction, [name]: value };
        await this.setTransaction(tr);
      }
    },
    async onClickValidate() {
      await this.captureTransaction(this.transaction.id);
      this.$router.push({ name: 'transactions' });
    },
    onBlur(e) {
      const {
        name, required,
      } = e.target;
      this.errors = { ...this.errors, [name]: validate(this.transaction[name], name, required) };
    },
    onFocus(name) {
      this.validationMap[name].validable = true;
    },
    backToHome() {
      this.$router.push({ name: 'transactions' });
    },
    initValidationMap(validable = true) {
      return Object.keys(this.validationMap).forEach((k) => {
        this.validationMap[k].validable = validable;
        // this.errors = {
        //   ...this.errors,
        //   [k]: validate(this.transaction[k], k, this.validationMap[k].required),
        // };
        if (k === 'contracts') {
          let contracts;
          if (this.transaction.contracts && this.transaction.contracts.filter((c) => c.selected).length === 0) {
            contracts = { valid: false, error: 'Veuillez sélectionner au moins un moyen de paiement' };
          } else {
            contracts = { valid: true };
          }
          this.errors[k] = contracts;
        } else {
          this.errors[k] = validate(this.transaction[k], k, this.validationMap[k].required);
        }
      });
    },
    async onEnter(params) {
      if (params.id) {
        await this.loadTransaction(params.id);
        if (this.transaction.isUpdatable) {
          this.editMode = true;
          this.previousSeller = this.transaction.seller;
          const tr = { ...this.transaction, seller: '' };
          await this.setTransaction(tr);
          this.focusSellerInput();
        }
        this.initValidationMap();
      } else {
        this.newTransaction();
        this.initValidationMap(false);
      }
      this.paiementList = await this.paiementListBuild();
    },
    getInputError(name) {
      return {
        'is-invalid': this.validationMap[name].validable && this.errors[name].valid === false,
      };
    },
    displayFormHelper(force = true) {
      if (this.disabledSaveButton) {
        this.formHelper = force;
      }
    },
    focusSellerInput() {
      this.$refs.sellerInput.focus();
    },
  },
  computed: {
    ...mapState([
      'transaction',
      'merchant',
      'permissions',
    ]),
    ...mapGetters([
      'isPreviewLoading',
      'isSaveLoading',
      'isGetLoading',
      'isCaptureLoading',

    ]),
    contracts() {
      if (!this.transaction || !this.transaction.contracts) {
        return [];
      }
      const unselectedPaymentOption = this.transaction.contracts.reduce((acc, e) => {
        if (e.selected) {
          acc.paymentDirect = acc.paymentDirect && e.paymentDirect;
          acc.paymentCapture = acc.paymentCapture && e.paymentCapture;
        }
        return acc;
      }, {
        paymentDirect: true,
        paymentCapture: true,
      });
      return this.transaction.contracts.map((o) => {
        const e = { ...o, origin: o };
        if (!this.transaction.isUpdatable
                     || this.transaction.contracts.length === 1) {
          e.disabled = true;
        } else if (!unselectedPaymentOption.paymentDirect && !e.paymentCapture) {
          e.disabled = true;
        } else if (!unselectedPaymentOption.paymentCapture && !e.paymentDirect) {
          e.disabled = true;
        } else if (!e.paymentDirect && !e.paymentCapture) {
          e.disabled = true;
        }
        return e;
      });
    },

    disabledSaveButton() {
      return this.listErrors.length > 0 || !this.permissions['EPM.pr.write'];
    },
    listErrors() {
      const errorList = Object.keys(this.errors)
        .filter((f) => this.errors[f].valid === false
        || (this.errors[f].valid === null && this.validationMap[f].required))
        .map((err) => this.validationMap[err].label);
      return errorList;
    },
    expirationsPresets() {
      if (this.merchant && this.merchant.settings) {
        const { expirations_presets } = this.merchant.settings;
        if (expirations_presets && expirations_presets.length > 0) {
          return expirations_presets;
        }
      }
      return null;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onEnter(to.params);
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "../styles/settings";

  form.is-loading {
    filter: grayscale(90%);
    pointer-events: none;
    opacity: .6;
  }

  .page-headings {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;
  }

  input {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin:0;
    }
  }

  .required{
    > label {
      &::after {
        content: '*';
        display: inline-block;
        color: $orange;
      }
    }
  }

  .input-group-text {
    background-color: $gray-200;
  }

  .form-fields {
    margin-bottom: 5em;
  }

  .form-group {
    margin-bottom: 1.5em;
  }

  .form-control {
    border: none;
    box-shadow: 0 0 0 2px rgba($black, .1);

    &:focus {
      box-shadow: 0 0 0 2px rgba($primary, .3);
    }

    &.is-invalid {
      box-shadow: 0 0 0 2px rgba($red, .3);

      &:focus {
        box-shadow: 0 0 0 2px rgba($red, .3);
      }
    }

    &.amount {
      font-size: 2.5em;
      text-align: center;
    }
  }

  .is-invalid.error-alt {
    background-image: none;
  }

  .form-actions {
    background-color: $primary;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 1em 2em;
    position: fixed;
    right: 0;
    z-index: 1030;
    transition: all ease-in-out .3s;

    .btn-back {
      color: white;
      background-color: rgba(white, 0);
      border: none;

      &:hover {
        background-color: rgba(white, .1);
      }
    }
    .btn-light {
      color: $primary;
    }
    .btn-outline-light {
      &:hover:not(:disabled) {
        color: $primary;
        background-color: white;
        border-color: white;
      }
      &:disabled {
        opacity: 1;
      }
    }
  }

  .sticky-top {
    top: 20px;
  }

  .small-text {
    display: block;
    color: $gray-600;
    font-style: italic;
    &.is-invalid {
      color: $red;
      text-align: right;
    }
  }

  .message-box {
    min-height: 15em;
  }

  button .spinner-border {
    vertical-align: middle;
    // width: 1.3rem;
    // height: 1.3rem;
  }

  .btn-tooltip-wrapper {
    position: relative;
  }

  .form-errors-info {
    border-radius: 4px;
    bottom: 65px;
    box-shadow: 0 10px 30px rgba(#000, .2);
    color: $white;
    // font-size: 0.8em;
    padding: 1em;
    position: absolute;
    right: 0;
    width: 320px;

    &::after {
      border: solid 10px transparent;
      border-top-color: $orange;
      bottom: -20px;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      right: 15%;
      width: 1px;
    }

    .form-helper-title {
      font-size: 1em;
    }
  }
</style>
