<template>
  <div class="date-range-picker d-flex align-items-stretch">
    <button class="btn btn-sm btn-outline-primary reportrange-text dropdown-toggle"
     data-test="b-status"
      :class="{'reportrange-text-long': label.length>15}" @click="togglePicker">
      <slot name="input">
          <span>{{ label }}</span>
          <b class="caret"></b>
      </slot>
    </button>
    <transition name="slide-fade" mode="out-in">
      <div class="dropdown-menu ltr" :class="pickerStyles()" v-if="open"
            v-on-clickaway="clickAway">
          <div class="presets hidden-xs">
            <ul v-if="presets" class="mb-0">
              <li
                  v-for="(key, value) in presets"
                  :key="key"
                  :class="{ active: key === activePreset}"
                  @keypress="activePreset=key; open = false; $emit('change', value)"
                  @click="activePreset=key; open = false; $emit('change', value)">
                  <a class="btn btn-outline" :data-test="'cal_' + key" >{{key}}</a></li>
            </ul>
          </div>

      </div>
    </transition>
  </div>
</template>

<script>

/* eslint-disable no-plusplus */

// Code from https://github.com/Innologica/vue2-daterange-picker

import { mixin as clickaway } from 'vue-clickaway';

export default {

  mixins: [clickaway],

  props: {
    value: String,
  },

  data() {
    const presets = {
      '': 'Tous',
      SENT: 'Demande Envoyée',
      ABORT: 'Demande annulée',
      OPENED: 'Mail ouvert',
      PAYMENT_STARTED: 'En cours',
      PAYMENT_CANCELLED: 'Paiement annulé',
      PAYMENT_ACCEPTED: 'À valider',
      PAYMENT_COMPLETE: 'Payé',
      PAYMENT_REFUSED: 'Paiement refusé',
      PAYMENT_ERROR: 'Echec paiement',
      EXPIRED: 'Paiement expiré',
    };
    let activePreset = 'Tous';
    if (this.value) {
      activePreset = presets[this.value];
    }
    return {
      open: false,
      presets,
      activePreset,
    };
  },
  methods: {

    togglePicker() {
      this.open = !this.open;
    },
    pickerStyles() {
      return {
        'show-calendar': this.open,
        opensright: this.opens === 'right',
        opensleft: this.opens === 'left',
        openscenter: this.opens === 'center',
      };
    },

    clickAway() {
      if (this.open) {
        this.open = false;
      }
    },

  },
  computed: {

    label() {
      return this.activePreset === 'Tous' ? 'Statut' : this.activePreset;
    },

  },

};

</script>

<style lang="scss" scoped>
  @import '@/styles/settings.scss';

  .date-range-picker {
    position: relative;
    display: inline-block;
    // &:hover {
    //   .reportrange-text {
    //     background-color: $primary;
    //     color: #fff;
    //   }
    // }
  }
  // .range_inputs {
  //     margin-bottom: 10px;
  // }

  .reportrange-text {
    text-align: center;
    cursor: pointer;
    padding: 5px 10px;
    // border: 1px solid #ccc;
    // border-radius: 0.2rem;
    width: 10em;
  }
  .reportrange-text-long span {
    font-size: 0.9em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;

  }
  .date-range-picker .show-calendar {
      display: inline-flex;
  }

  .date-range-picker .ranges {
      width: 160px;
  }

  .show-calendar {
      display: block;
      width: auto;
      min-width: none;
  }

  .calendar {
    padding: 0 1em;
    border-left: 2px solid #eee;

  }

  .date-range-picker-input {
    padding-top: 0.3em;
    input {
      font-size: 0.8em;
      text-align: center;
      border-radius: 0;
      padding: 0;
    }
  }
  .dropdown-menu {
    border-radius: 0.2rem;
    box-shadow: 0 1em 60px rgba(0,0,0,.2);
    background-color: #fff;

  }
  div.dropdown-menu.opensleft {
      top: 31px;
      right: 10px;
      left: auto;
  }

  div.dropdown-menu.openscenter {
      top: 31px;
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
  }

  div.dropdown-menu.opensright {
      top: 45px;
      left: 0;
      right: auto;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
      transition: all .2s ease;
  }

  .slide-fade-leave-active {
      transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active for <2.1.8 */
  {
      transform: translateY(-7px);
      opacity: 0;
  }

  .presets {
    width: 140px;
    ul {
      list-style: none;
      padding: 0;
    }
    a {
      display: block;
      text-align: left;
      padding: 0.1em 0.5em;
      border-radius: 0;
      font-size: 0.9em;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
    .active a {
      font-weight: 600;
      background-color: $primary;
      color:#fff;
      &:hover {
        color: #fff;
      }
    }
    .range-inputs {
      text-align: right;
      padding-right: .5em;
    }
  }
</style>
