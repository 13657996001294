<template>
  <div class="auth-return">

      <span
            class="spinner-border text-primary spinner-border"
            role="status"
            aria-hidden="true"></span>
      <div>Patientez...</div>

    <!-- <button class="btn btn-primary" @click="process">Go</button> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['doAuth']),
    async process() {
      const { code } = this.$route.query;
      await this.doAuth(code);
      setTimeout(() => {
        this.$router.push({ name: 'transactions' });
      }, 500);
    },
  },
  mounted() {
    this.process();
  },
};
</script>
<style lang="scss" scoped>
.auth-return {
  text-align: center;
  margin-top: 5em;
  color: #aaa;
}
.spinner-border  {
  width: 3rem;
  height: 3rem;
  margin: 1em;
}
</style>
