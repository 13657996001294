<template>
  <input type="text"
         :value="formattedValue"
         @input="onInput"
         @focus="onFocus"
         @blur="$emit('blur', $event)"
          />
</template>
<script>
/* eslint-disable no-param-reassign */

function toStr(value) {
  return value ? value.toString() : '';
}

function between(min, n, max) {
  return Math.max(min, Math.min(n, max));
}

// Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
function fixed(precision) {
  return between(0, precision, 20);
}

function onlyNumbers(input) {
  return toStr(input).replace(/\D+/g, '') || '0';
}

function numbersToCurrency(numbers, precision) {
  const exp = 10 ** precision;
  const float = parseFloat(numbers) / exp;
  return float.toFixed(fixed(precision));
}

function addThousandSeparator(integer, separator) {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
}

function joinIntegerAndDecimal(integer, decimal, separator) {
  return decimal ? integer + separator + decimal : integer;
}

function format(input, opt = {}) {
  if (typeof input === 'number') {
    input = input.toFixed(fixed(opt.precision));
  }
  const negative = input.indexOf('-') >= 0 ? '-' : '';

  const numbers = onlyNumbers(input);
  const currency = numbersToCurrency(numbers, opt.precision);
  const parts = toStr(currency).split('.');
  let integer = parts[0];
  const decimal = parts[1];
  integer = addThousandSeparator(integer, opt.thousands);
  return opt.prefix + negative + joinIntegerAndDecimal(integer, decimal, opt.decimal) + opt.suffix;
}

function unformat(input, precision) {
  const negative = input.indexOf('-') >= 0 ? -1 : 1;
  const numbers = onlyNumbers(input);
  const currency = numbersToCurrency(numbers, precision);
  return parseFloat(currency) * negative;
}

function setCursor(el, position) {
  const setSelectionRange = () => { el.setSelectionRange(position, position); };
  if (el === document.activeElement) {
    setSelectionRange();
    setTimeout(setSelectionRange, 1); // Android Fix
  }
}

export default {
  props: {
    value: {
      required: true,
      type: [Number, String],
      default: 0,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: () => 2,
    },
    decimal: {
      type: String,
      default: () => '.',
    },
    thousands: {
      type: String,
      default: () => ' ',
    },
    prefix: {
      type: String,
      default: () => '',
    },
    suffix: {
      type: String,
      default: () => ' €',
    },
  },
  data() {
    return {
      formattedValue: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const formatted = format(newValue, this.$props);
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted;
        }
      },
    },
  },
  methods: {
    onInput(evt) {
      const el = this.$el;
      let positionFromEnd = el.value.length - el.selectionEnd;
      el.value = format(el.value, this);
      positionFromEnd = Math.max(positionFromEnd, this.suffix.length); // right
      positionFromEnd = el.value.length - positionFromEnd;
      positionFromEnd = Math.max(positionFromEnd, this.prefix.length + 1); // left
      setCursor(el, positionFromEnd);
      if (evt) {
        // eslint-disable-next-line no-underscore-dangle
        evt.target._value = unformat(el.value, this.precision);
        this.$emit('input', evt);
      }
    },
    onFocus(e) {
      const el = this.$el;
      setCursor(el, el.value.length - this.suffix.length);
      this.$emit('focus', e);
    },
  },
  mounted() {
    this.onInput();
  },
};
</script>
