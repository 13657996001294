const regexEmail = /^[\w.-]+@[\w.-]+\.[a-z0-9]+$/i;
const regexAlphaNum = /^[0-9a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ -]+$/;

function isValidName(value) {
  return typeof value === 'string' && regexAlphaNum.test(value);
}

function isValidEmail(value) {
  return typeof value === 'string' && regexEmail.test(value);
}

function isValidRef(value) {
  return typeof value === 'string' && (/^[\w#_| /@-]*$/.test(value)) && value.length <= 32;
}

function isValidCapture(value) {
  return typeof value === 'boolean';
}

/**
 * Check if value is valid amount (integer part
 * can't exceed 5 digits, decimal part can't exceed 2 digits)
*/
function isValidAmount(value) {
  let pValue = value;
  if (typeof value === 'number') {
    pValue = String(pValue);
  }
  return typeof pValue === 'string' && /^(0|([1-9]\d{0,4}))([.,]\d{1,2})?$/.test(pValue);
}

export default function validate(value, name, required = false) {
  if (typeof value === 'string') {
    if (required && !value.length) {
      return { valid: false, error: 'Ce champs est requis' };
    }
  }
  if (typeof value === 'number') {
    if (required && value === 0) {
      return { valid: false, error: 'Ce champs est requis' };
    }
  }
  if (name === 'seller') {
    if (!isValidName(value)) {
      return { valid: false, error: 'Votre nom doit comporter uniquement des caractères alphanumériques' };
    }
  }

  if (name === 'email') {
    if (!isValidEmail(value)) {
      return { valid: false, error: "L'email renseigné est incorrect" };
    }
  }
  if (name === 'amount') {
    if (value < 1) {
      return { valid: false, error: 'Le montant doit être supérieur à 1 €' };
    }
    if (!isValidAmount(value)) {
      return { valid: false, error: 'Le montant renseigné est incorrect' };
    }
  }
  if (name === 'ref') {
    if (!isValidRef(value)) {
      return {
        valid: false,
        error: 'La référence doit comporter uniquement des caractères alphanumériques sans accents et ne doit pas dépasser 32 caractères',
      };
    }
  }
  if (name === 'capture') {
    if (!isValidCapture(value)) {
      return { valid: false, error: 'Veuillez sélectionner le mode de paiement' };
    }
  }
  return { valid: true };
}
