<template>
  <div class="d-inline-flex">
    <div class="text-center spacer"
     v-if="permissions['EPM.habilitations.user'] || permissions['EPM.admin.shop.write'] " > </div>
    <div class="text-center">
      <a class="btn btn-sm btn-light ml-1 link-hi"  v-if="permissions['EPM.habilitations.user']"
       target="_blank" :href="habilitationsUrl"
        >Habilitations</a
      >
    </div>

    <div class="text-center">
      <a class="btn btn-sm btn-light ml-1 link-admin"  v-if="permissions['EPM.admin.shop.write']"
       target="_blank" :href="configurationUrl"
        >Configuration</a
      >
    </div>

    <div class="text-center spacer"
    v-if="permissions['EPM.habilitations.user'] || permissions['EPM.admin.shop.write']" > </div>

     <user-menu-combo   @signOut="signOut" :helpUrl="helpUrl" :helpUrlHabilitation="helpUrlHabilitation" />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import multiClient from '../../lib/multiClient';
import UserMenuCombo from './UserMenuCombo.vue';

export default {
  components: { UserMenuCombo },
  computed: {
    ...mapState({
      user: (state) => state.user.cognito,
      loading: (state) => state.user.loading,
    }),
    ...mapState([
      'permissions',
    ]),
    helpUrl() {
      return multiClient.get('helpUrl');
    },
    helpUrlHabilitation() {
      return process.env.VUE_APP_HELP_URL_HABILITATION;
    },
    habilitationsUrl() {
      return `${multiClient.get('hiFront')}/?a=${this.user.signInUserSession.accessToken.jwtToken}`;
    },
    configurationUrl() {
      return `${multiClient.get('epmAdminFront')}/?a=${this.user.signInUserSession.accessToken.jwtToken}`;
    },
  },

  methods: {
    ...mapActions('user', ['signOut']),
  },
};
</script>
<style scoped lang="scss" >
.help {
  /* https://materialdesignicons.com/icon/help-circle */
  background: url("help.svg");
  background-repeat: no-repeat;
  padding-top: 20px;
  padding-left: 15px; /* width of the image plus a little extra padding */
  display: block;
  /* Other styles here */
}

.spacer {
  margin-left: 60px;
}

.link-hi {

  display: inline-block;
    position: relative;

    overflow: hidden;
    padding-left: 28px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      background-image: url('./account.svg');
      background-size: 28px 28px;
      height: 28px;
      width: 28px;
      margin-left: -28px;
      top: -2px;
    }

}

.link-admin {

  display: inline-block;
    position: relative;

    overflow: hidden;
    padding-left: 28px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      background-image: url('./admin.svg');
      background-size: 28px 28px;
      height: 28px;
      width: 28px;
      margin-left: -28px;
      top: -2px;
    }

}

</style>
