<template>

  <div
        class=" help"

        :style="
    [{ backgroundImage: 'url(' + require('./images/'+name+'.svg') + ')'
   }]"
      />
      <!-- ,{backgroundSize:'100%'},{backgroundPosition:'center bottom'} -->

  <!-- <v-icon class="ma-1">mdi-{{ name }}</v-icon> -->
</template>

<script>
export default {
  props: {
    name: {},
    size: {
      default: 'normal',
    },
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  /* https://materialdesignicons.com/icon/help-circle */
  background-repeat: no-repeat;
  padding-top: 22px;
  padding-left: 22px; /* width of the image plus a little extra padding */
  display: block;
  /* Other styles here */
}
</style>
