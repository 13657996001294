<template>
    <table class="table-condensed">
        <thead>
        <tr>
            <th class="prev available" @click="$emit('prev-month')">&lt;</th>
            <th colspan="5" class="month">{{monthName}} {{year}}</th>
            <th class="next available" @click="$emit('next-month')">&gt;</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <th
              v-for="weekDay in locale.daysOfWeek"
              :key="weekDay">{{weekDay}}</th>
        </tr>
        <tr
          v-for="(dateRow,i) in calendar"
          :key="i">
            <slot name="date-slot"
              v-for="date in dateRow">
                <td
                  :key="+date"
                  :class="dayClass(date)"
                  @click="$emit('date-click', date)"
                  @keypress="$emit('date-click', date)"
                  @focus="$emit('hover-date', date)"
                  @mouseover="$emit('hover-date', date)"
                  >{{dateNum(date) }}</td>
            </slot>
        </tr>
        </tbody>
    </table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Calendar',
  props: ['monthDate', 'locale', 'start', 'end'],
  components: { },
  methods: {
    dateNum(value) {
      return value.date();
    },
    dayClass(date) {
      const dt = new Date(date);
      return {
        off: date.month() !== this.month,
        weekend: date.isoWeekday() > 5,
        today: dt.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0),
        active: dt.setHours(0, 0, 0, 0) === new Date(this.start).setHours(0, 0, 0, 0)
                || dt.setHours(0, 0, 0, 0) === new Date(this.end).setHours(0, 0, 0, 0),
        'in-range': dt >= new Date(this.start).setHours(0, 0, 0, 0)
                && dt <= new Date(this.end).setHours(0, 0, 0, 0),
        'is-start': dt.setHours(0, 0, 0, 0) === new Date(this.start).setHours(0, 0, 0, 0),
        'is-end': dt.setHours(0, 0, 0, 0) === new Date(this.end).setHours(0, 0, 0, 0),
      };
    },
  },
  computed: {
    monthName() {
      return this.locale.monthNames[this.monthDate.getMonth()];
    },
    year() {
      return this.monthDate.getFullYear();
    },
    month() {
      return this.monthDate.getMonth();
    },
    calendar() {
      const { month } = this;
      const year = this.monthDate.getFullYear();
      // const daysInMonth = new Date(year, month, 0).getDate();
      const firstDay = moment(new Date(year, month, 1));
      const lastDay = firstDay.clone().endOf('month');
      const calendar = [];

      const startDay = firstDay.clone().subtract(firstDay.isoWeekday() - 1, 'd');
      const endDay = lastDay.clone().add(7 - lastDay.isoWeekday(), 'd');
      let i = 0;
      for (const d = startDay; d < endDay; d.add(1, 'd')) {
        // eslint-disable-next-line no-plusplus
        const k = Math.floor(i++ / 7);
        calendar[k] = calendar[k] || [];
        calendar[k][d.isoWeekday() - 1] = d.clone();
      }
      return calendar;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import '@/styles/settings.scss';

  $dayRadius: 5px;

  .prev, .next, tbody td {
    cursor: pointer;
  }
  td, th {
    text-align: center;
    padding: 0.3em;
    font-size: 0.8em;
  }
  td.today {
    font-weight: bold;
    text-decoration: underline;
  }

  td.weekend {

  }

  td.in-range {
    background-color: $gray-400;
  }

  td.active {
    font-weight: bold;
    background-color: $primary;
    color: white;
  }

  td.is-start {
    border-radius: $dayRadius 0 0 $dayRadius;
  }

  td.is-end {
    border-radius: 0 $dayRadius $dayRadius 0;
  }

  td.is-start.is-end {
    border-radius: $dayRadius;
  }
  td.off {
    opacity: .2;
  }

</style>
