<template>
  <v-select
    :options="merchantsSorted"
    :resetOnOptionsChange="false"
    :clearable="false"
    :disabled="disabled"
    label="name"
    v-model="selected"
    @keydown.enter.prevent=""
    data-test="s-merchant"
      />
</template>
<script>

import { mapState, mapActions } from 'vuex';

import vSelect from 'vue-select';

export default {
  components: { vSelect },
  props: ['value'],
  model: {
    prop: 'value',
    event: 'input',
  },
  computed: {
    ...mapState([
      'merchants',
      'merchant',
      'transaction',
    ]),
    selected: {
      get() {
        return this.merchants.find((m) => m.id === this.merchant.id);
      },
      set(v) {
        this.setMerchant(v ? v.id : null);
      },
    },
    merchantsSorted() {
      return [...this.merchants]
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    },
    disabled() {
      return Object.keys(this.transaction).length > 0;
    },
  },
  methods: {
    ...mapActions(['setMerchant']),
  },
};
</script>
<style lang="scss" scoped>
.v-select {
  min-width: 17em;
}
</style>
