import axios from 'axios';

const instances = new Map();

const {
  VUE_APP_API_ENDPOINT,
} = process.env;

if (!VUE_APP_API_ENDPOINT) {
  console.error('Not registered api ');
}
function redirectToCognito() {
  console.error('TODO: route to login');
}

export default {
  connectStore(store) {
    this.store = store;
  },

  authenticate(token) {
    this.token = token;
  },

  name(name) {
    const instKey = name;
    if (!instances.has(instKey)) {
      const inst = axios.create({
        baseURL: `${VUE_APP_API_ENDPOINT}`,
        timeout: 30000,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        crossDomain: true,
        responseType: 'json',
      });
      inst.interceptors.request.use((config) => {
        this.store.commit('setLoading', {
          name,
          loading: true,
        });
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${this.token}`;
        return config;
      });

      inst.interceptors.response.use(
        (response) => {
          this.store.commit('setLoading', {
            name,
            loading: false,
          });
          localStorage.setItem('session_lastRequest_date', Date.now());
          return response;
        },
        (err) => {
          if (err && err.response && err.response.status === 401) {
            redirectToCognito();
          }
          let message;
          if (err.response) {
            message = err.response.data ? err.response.data.message : err.response.data;
            if (!message) {
              message = err.response;
            }
          } else {
            message = 'Une erreur est survenue';
          }
          this.store.dispatch('notifications/add', {
            title: 'Erreur',
            type: 'error',
            message,
          });
          this.store.commit('setLoading', {
            name,
            loading: false,
          });
          console.error(message);
          return err;
        },
      );

      instances.set(instKey, inst);
    }
    return instances.get(instKey);
  },
};
