import Vue from 'vue';
import Amplify from '@aws-amplify/core';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import api from './lib/api';
import './lib/moment-fr';
import awsExports from './aws-exports';
import './locale/AmplifyI18n';
import './styles/main.scss';

Vue.config.productionTip = false;
Amplify.configure(awsExports);

api.connectStore(store);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
