var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"editor"},[(_vm.editor)?_c('div',{class:[{ 'disabled':  _vm.readonly }, 'ProseMirror-menubar']},[_c('button',{class:[{ 'disabled':  _vm.readonly }, 'menubar__button',

         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('bold') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('icon',{attrs:{"name":"format-bold"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('italic') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('icon',{attrs:{"name":"format-italic"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('strike') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},[_c('icon',{attrs:{"name":"format-strikethrough"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('underline') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}},[_c('icon',{attrs:{"name":"format-underline"}})],1),_c('span',{staticClass:"ProseMirror-menuseparator"}),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('heading', { level: 1 }) }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},[_c('icon',{attrs:{"name":"format-header-1"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('heading', { level: 2 }) }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},[_c('icon',{attrs:{"name":"format-header-2"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('heading', { level: 3 }) }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},[_c('icon',{attrs:{"name":"format-header-3"}})],1),_c('span',{staticClass:"ProseMirror-menuseparator"}),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('bulletList') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('icon',{attrs:{"name":"format-list-bulleted"}})],1),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('orderedList') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('icon',{attrs:{"name":"format-list-numbered"}})],1),_c('span',{staticClass:"ProseMirror-menuseparator"}),_c('button',{staticClass:"menubar__button",class:['menubar__button',
        { 'disabled':  _vm.readonly },
         { 'is-active, ProseMirror-menu-active': _vm.editor.isActive('blockquote') }],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().toggleBlockquote().run()}}},[_c('icon',{attrs:{"name":"format-quote-open"}})],1),_c('span',{staticClass:"ProseMirror-menuseparator"}),_c('button',{class:[{ 'disabled':  _vm.readonly }, 'menubar__button'],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},[_c('icon',{attrs:{"name":"undo"}})],1),_c('button',{class:[{ 'disabled':  _vm.readonly }, 'menubar__button'],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},[_c('icon',{attrs:{"name":"redo"}})],1)]):_vm._e(),_c('editor-content',{staticClass:"editor__content",class:{ 'disabled':  _vm.readonly },attrs:{"editor":_vm.editor,"data-test":_vm.dataTest}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }