/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
let i = 0;

const state = {
  notifications: [],
};

const mutations = {
  add(state, notification) {
    state.notifications.push(notification);
  },
  drop(state, notification) {
    let idx = -1;
    if (typeof notification === 'number') {
      for (let i = 0; i < state.notifications.length; i++) {
        if (state.notifications[i].id === notification) {
          idx = i;
          break;
        }
      }
    } else {
      idx = state.notifications.indexOf(notification);
    }
    if (idx >= 0) {
      state.notifications.splice(idx, 1);
    }
  },
};

const actions = {
  add: ({
    commit,
    dispatch,
  }, notification) => {
    const id = i++;
    if (notification.type === 'error') {
      console.error(notification.message);
    }
    commit('add', {
      id,
      title: notification.title,
      type: notification.type,
      message: notification.message,
    });

    setTimeout(() => {
      dispatch('drop', id);
    }, (notification.ttl || 5) * 1000);
  },

  drop: ({
    commit,
  }, notification) => commit('drop', notification),

  addError: ({
    dispatch,
  }, err) => dispatch('add', {
    title: 'Erreur',
    type: 'error',
    message: err,
  }),
};

const getters = {
  list: (state) => state.notifications,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
