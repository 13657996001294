import TransactionsList from '../views/TransactionsList.vue';
import TransactionForm from '../views/TransactionForm.vue';
import AuthReturn from '../views/AuthReturn.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: TransactionsList,
    meta: { requiresAuth: true, permissions: ['EPM.pr.read'] },
  },
  { path: '/transactions', name: 'transactions', redirect: '/' },
  {
    path: '/new',
    name: 'transaction-new',
    component: TransactionForm,
    meta: { requiresAuth: true, permissions: ['EPM.pr.read'] },
  },
  {
    path: '/auth',
    component: AuthReturn,
  },
  {
    name: 'ChangePwd',
    path: '/pwd',
    component: () => import(/* webpackChunkName: "ChangePwd" */ '../views/ChangePwd.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:id',
    name: 'transaction-edit',
    component: TransactionForm,
    meta: { requiresAuth: true, permissions: ['EPM.pr.read'] },
  },

];

export default routes;
