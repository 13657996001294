import { Translations } from '@aws-amplify/ui-components';
import { I18n } from '@aws-amplify/core';

import dictionary from './AmplifyI18nAWS';

// https://github.com/aws-amplify/amplify-js/blob/main/packages/aws-amplify-react-native/src/AmplifyI18n.ts
I18n.putVocabularies(dictionary);

const dict = {
  en: {
    'Custom auth lambda trigger is not configured for the user pool.': 'Please enter your password',
  },
  fr: {
    // https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/common/Translations.ts
  // [Translations.SIGN_IN_HEADER_TEXT]: 'Compte Edel',
  // [Translations.SIGN_IN_ACTION]: 'Connexion',
    [Translations.USERNAME_LABEL]: 'e-mail *',
    // [Translations.USERNAME_PLACEHOLDER]: 'adresse e-mail',
    [Translations.PASSWORD_LABEL]: 'Mot de passe *',
    // [Translations.PASSWORD_PLACEHOLDER]: 'Mot de passe',
    // [Translations.RESET_PASSWORD_TEXT]: 'Mot de passe oublié ?',
    [Translations.FORGOT_PASSWORD_TEXT]: ' ',
    // reset password
    // [Translations.RESET_YOUR_PASSWORD]: 'Mot de passe oublié ?',
    // [Translations.SEND_CODE]: 'Recevoir un code par e-mail',
    // [Translations.BACK_TO_SIGN_IN]: 'Retour',
    // https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/common/AuthErrorStrings.ts
    // [Translations.EMPTY_USERNAME]: "L'email ne peut pas être vide",

    'User does not exist.': "Le nom d'utilisateur ou le mot de passe est incorrect",
    'Incorrect username or password.': "Le nom d'utilisateur ou le mot de passe est incorrect",
    'Custom auth lambda trigger is not configured for the user pool.': 'Le mot de passe doit être renseigné',
    'Username/client id combination not found.': 'Utilisateur inconnu.',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': "Il n'est pas possible de réinitialiser votre mot de passe car votre email n'est pas vérifié",
    'User password cannot be reset in the current state.': "Il n'est pas possible de réinitialiser votre mot de passe car votre compte est bloqué",
    'Invalid verification code provided, please try again.': "Le code de vérification n'est pas correct",
    'New password': 'Nouveau mot de passe',
    'Verification code': 'Code de vérification',
    'Password reset required for the user': 'Votre mot de passe doit être changé.',
    'User is disabled.': 'Votre utilisateur a été désactivé',
    'Temporary password has expired and must be reset by an administrator.': 'Votre mot de passe temporaire a expiré. Veuillez contacter le support.',

  },
};

I18n.putVocabularies(dict);
