<template>
<div>
  <div class="editor">
    <div v-if="editor"  :class="[{ 'disabled':  readonly }, 'ProseMirror-menubar']" >
      <button
        type="button"
        :class="[{ 'disabled':  readonly }, 'menubar__button',

         { 'is-active, ProseMirror-menu-active': editor.isActive('bold') }]"
        :disabled="readonly"
        @click="editor.chain().focus().toggleBold().run()"

      >
        <icon name="format-bold" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('italic') }]"
      >
        <icon name="format-italic" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleStrike().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('strike') }]"
      >
        <icon name="format-strikethrough" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('underline') }]"
      >
        <icon name="format-underline" />
      </button>
      <span class="ProseMirror-menuseparator"></span>
      <!-- <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleCode().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('code') }"
      >
        code
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().unsetAllMarks().run()"
      >
        clear marks
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().clearNodes().run()"
      >
        clear nodes
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().setParagraph().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('paragraph') }"
      >
        paragraph
      </button>-->
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('heading', { level: 1 }) }]"
      >
        <icon name="format-header-1" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('heading', { level: 2 }) }]"
      >
        <icon name="format-header-2" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('heading', { level: 3 }) }]"
      >
        <icon name="format-header-3" />
      </button>
      <span class="ProseMirror-menuseparator"></span>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('bulletList') }]"
      >
        <icon name="format-list-bulleted" />
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('orderedList') }]"
      >
        <icon name="format-list-numbered" />
      </button>
      <span class="ProseMirror-menuseparator"></span>
      <!-- <button type="button" class="menubar__button"  :disabled="readonly"
      @click="editor.chain().focus().toggleCodeBlock().run()"
      :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('codeBlock') }"
    >
      code block
    </button> -->
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="['menubar__button',
        { 'disabled':  readonly },
         { 'is-active, ProseMirror-menu-active': editor.isActive('blockquote') }]"
      >
        <icon name="format-quote-open" />
      </button>
      <!-- <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        horizontal rule
      </button>
      <button
        type="button" class="menubar__button"
        :disabled="readonly"
        @click="editor.chain().focus().setHardBreak().run()"
      >
        hard break
      </button> -->
      <span class="ProseMirror-menuseparator"></span>
      <button
        type="button"
          :class="[{ 'disabled':  readonly }, 'menubar__button']"
        :disabled="readonly"
        @click="editor.chain().focus().undo().run()"
      >
        <icon name="undo" />
      </button>
      <button
        type="button" :class="[{ 'disabled':  readonly }, 'menubar__button']"
        :disabled="readonly"
        @click="editor.chain().focus().redo().run()"
      >
        <icon name="redo" />
      </button>
    </div>
    <editor-content class="editor__content"
        :editor="editor"
        :data-test="dataTest"
        :class="{ 'disabled':  readonly }" />
  </div>
</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Icon from './Icon.vue';

export default {
  props: {
    dataTest: String,
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: String,
  },
  components: {
    EditorContent,
    Icon,
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    },
    readonly(v) {
      this.editor.setOptions({ editable: !v });
    },
  },

  mounted() {
    const ex = [];
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        ...ex,
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
      editable: !this.readonly,
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">

.menubar__button {
  border-width: 0;
  background-color: white;
}

.ProseMirror-menu-active {
  background: #eee;
  border-radius: 4px;
}

.character-count {
  text-align: right ;
}
/* Basic editor styles */
.ProseMirror-menuseparator {
  border-right: 1px solid #ddd;
  margin-right: 3px;
}

.ProseMirror-menubar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  position: relative;
  min-height: 1em;
  color: #666;
  padding: 1px 6px;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid silver;
  background: white;
  z-index: 10;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  margin-bottom: 1em;
}

.ProseMirror {

  padding: 4px 8px 4px 14px;
  line-height: 1.2;
  outline: none;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
    padding-left: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }f

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  p {
    margin-bottom: 1em;
  }
}

#editor,
.editor {
  background: white;
  color: black;
  background-clip: padding-box;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  margin-bottom: 1em;
}

.disabled {
  background-color: #e9ecef ;
}

</style>
