<template>
  <nav class="nav-pagination">
    <ul class="pagination justify-content-end">
      <!-- <li class="page-item" v-if="transactionListCurrentPage > 1">
        <a class="page-link" href="#"
          @click.prevent="goToPage(transactionListCurrentPage-1)">&laquo;</a>
      </li>
      <li class="page-item"
        v-for="p in numPages" :key="p"
        :class="{active: p === transactionListCurrentPage}">
        <a class="page-link" href="#" @click.prevent="goToPage(p)">{{p}}</a>
      </li>
      <li class="page-item" v-if="transactionListCurrentPage<numPages">
        <a class="page-link" href="#"
          @click.prevent="goToPage(transactionListCurrentPage+1)">&raquo;</a>
      </li> -->
      <li class="page-item" v-if="transactionListCurrentPage > 1">
        <a class="page-link" href="#"
          @click.prevent="goToPage(transactionListCurrentPage-1)">&laquo;</a>
      </li>
      <li class="page-item">
        <a class="page-link" href="#">Page {{transactionListCurrentPage}}</a>
      </li>
      <li class="page-item" v-if="hasNext">
        <a class="page-link" href="#"
          @click.prevent="goToPage(transactionListCurrentPage+1)">&raquo;</a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState([
      'transactionList',
      'transactionListCurrentPage',
      'transactionListPageSize',
    ]),
    numPages() {
      return Math.ceil(this.transactionList.count / this.transactionListPageSize);
    },
    hasNext() {
      return this.transactionList.length > 0;
    },
  },
  methods: {
    ...mapActions([
      'loadTransactionList',
    ]),
    goToPage(n) {
      this.loadTransactionList(n);
    },
  },
};
</script>
