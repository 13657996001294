<template>
  <div class="preview-box" :class="loadingCls">
    <loader v-if="isPreviewLoading" data-test="preview-loader" />
    <div class="header">
      <div>
        <span class="label">De:</span>
        <span class="value"  v-if="preview">{{preview.from}}</span>
      </div>
      <div>
        <span class="label">à:</span>
        <span class="value"  v-if="preview">{{preview.to}}</span>
      </div>
      <div>
        <span class="label">Objet:</span>
        <span class="value"  v-if="preview">{{preview.subject}}</span>
      </div>
    </div>
    <div class="body">
      <iframe title="Aperçu" ref="frame"></iframe>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import Loader from './Loader.vue';

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapState(['preview']),
    ...mapGetters(['isPreviewLoading']),

    loadingCls() {
      return { 'is-loading': this.isPreviewLoading };
    },
  },
  methods: {
    renderPreview() {
      if (!this.preview || !this.$refs.frame) {
        return;
      }
      const doc = this.$refs.frame.contentWindow.document;
      doc.open();
      doc.write(this.preview.html);
      doc.close();
    },
  },
  mounted() {
    this.renderPreview();
  },
  watch: {
    preview() {
      this.renderPreview();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/settings";

.preview-box {
  border-radius: 5px;
  overflow: hidden;
  background-color: $primary;
  box-shadow: 0 0 10px rgba(#000, .1);
  position: relative;
  max-height: calc(100vh - 115px);

  &.is-loading {
    filter: grayscale(80%);
  }

  .header {
    padding: 1em;

  }
  .header>div {
    padding: .05em 0;
    font-size: .9em;
  }
  .label {
    color: lighten($primary, 40%);
    text-transform: capitalize;
    margin-right: .3em;
    display: inline-block;
    font-weight: 100;
  }
  .value {
    color: #fff;
  }
  .body {
    background: #fff;
    height: calc(100vh - 220px);
    max-height: 700px;
  }
  iframe {
    border: none;
    width: 100%;
    max-height: 700px;
    min-height: 100%;
  }
}

  .preview-header {
    display: flex;
    justify-content: space-between;
  }

  .preview-meta {
    flex-basis: calc(100% / 3);
    text-align: right;
    span {
      font-size: 0.8em;
      display: block;
    }
  }

  .preview-content {
    margin-top: 20px;
  }
</style>
