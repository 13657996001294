import numeral from 'numeral';

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('fr');

function currency(value) {
  if (value) {
    return numeral(value).format('0,0.00$');
  }
  return '';
}
function currencyFormat(value) {
  if (value) {
    return numeral(value).format('0,0.00');
  }
  return '';
}

export { currency, currencyFormat };
