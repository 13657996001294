<template>
  <button @click.prevent="click" :class="{'confirm-state': confirmState}" data-test="bt-confirm"  >
    <slot v-if="!confirmState"/>
    <span v-if="confirmState" class="confirm-message">
      {{confirmMessage || 'Re-cliquez pour confirmer'}}
    </span>
  </button>
</template>
<script>

export default {
  props: {
    confirmMessage: String,
    confirmDelay: {
      type: Number,
      default: 500,
    },
    disableConfirm: Boolean,
    confirmTimeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      confirmState: false,
      clickTime: 0,
      tm: null,
    };
  },
  methods: {
    click(e) {
      if (this.disableConfirm) {
        this.$emit('click', e);
      } else if (!this.confirmState) {
        this.confirmState = true;
        this.clickTime = +new Date();
        if (this.tm) {
          clearTimeout(this.tm);
        }
        this.tm = setTimeout(() => {
          this.confirmState = false;
        }, this.confirmTimeout);
      } else if (+new Date() - this.clickTime > this.confirmDelay) {
        this.confirmState = false;
        this.$emit('click', e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/settings.scss';

@keyframes blink-shadow {
    0% { box-shadow: 0 0 2px $white, 0 0 0 2px $orange; }
    50% { box-shadow: 0 0 2px $white, 0 0 0 5px $orange; }
    100% { box-shadow: 0 0 2px $white, 0 0 0 2px $orange; }
}

.confirm-state, .confirm-state:hover {
  animation: blink-shadow 1s ease-out infinite;
  background-color: $orange;
  color: $white !important;
}
</style>
