<template>
  <div>
    <div class="receipt" ref="receipt">
      <h1>EdelPay Mail</h1>
      <p>BANQUE EDEL</p>
      <p>&nbsp;</p>
      <p>{{receipt.card_type || receipt.payment_method}}</p>
      <p><b>{{receipt.transaction_date}}</b></p>
      <p class="text-uppercase">{{receipt.merchant_name}}</p>
      <p v-if="receipt.card_number">
        {{receipt.card_number.replace(/^.*(\d{4})$/,'############$1')}}
        </p>
      <p><b>{{receipt.transaction_id}}</b></p>
      <p>{{receipt.authorization_number}}</p>
      <p><b>{{ receipt.order_ref }}</b></p>
      <p>MONTANT :</p>
      <h1 class="text-right">{{formatCurrencyFormat(transaction.amount) }} EUR</h1>
      <p>DEBIT VADS</p>
      <p>-</p>
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-secondary" @click="print">Imprimer</button>
    </div>
  </div>
</template>
<script>
import { Printd } from 'printd';
import { currencyFormat } from '../filters/currency';

export default {
  props: {
    transaction: Object,
  },
  computed: {
    receipt() {
      return this.transaction.result;
    },
  },
  methods: {
    formatCurrencyFormat(e) {
      return currencyFormat(e);
    },
    print() {
      const d = new Printd();
      d.print(this.$refs.receipt, [`
        .receipt {
          font-family: monospace;
          padding: 2em 1em;
          border: 1px solid #ccc;
          display: inline-block;
          margin-top: 2em;
        }
        h1 {
          font-size: 1.8em;
        }
        h2 {
          font-size: 1.2em;
        }
        h1, h2 {
          font-weight: 500;
          margin: 0;
        }
        p, h1, h2 {
          margin-bottom: 0.3rem;
        }
        .text-uppercase {
          text-transform: uppercase;
        }
        .text-right {
          text-align: right;
        }

      `]);
    },
  },
};
</script>
<style lang="scss" scoped>
.receipt {
  color: #222;
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 300px;
  min-height: 300px;
  box-shadow:  0 0 5px rgba(0,0,0,.1);
  border-radius: 3px;
  background-color: #fff;
  font-family: monospace;
  padding: 1.5em;

  b {
    font-weight: 600;
  }

  .smaller {
    font-size: 0.9em;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    width: 40%;
    height: 10px;
    box-shadow: 0 5px 14px rgba(0,0,0,.7);
    z-index: -1;
    transition: all .3s ease-in-out;
  }

  &::before {
    left: 15px;
    transform: skew(-5deg) rotate(-5deg);
  }

  &::after {
    right: 15px;
    transform: skew(5deg) rotate(5deg);
  }
}

h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.2em;
}

p {
  margin-bottom: 0.5rem;
}

</style>
