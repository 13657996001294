<template>
  <div class="date-range-picker d-flex align-items-stretch">
    <button class="btn btn-sm btn-outline-primary reportrange-text dropdown-toggle"
     data-test="b-cal"
      :class="{'reportrange-text-long': label.length>15}" @click="togglePicker">
      <slot name="input">
          <span>{{ label }}</span>
          <b class="caret"></b>
      </slot>
    </button>
    <transition name="slide-fade" mode="out-in">
      <div class="dropdown-menu ltr" :class="pickerStyles()" v-if="open"
            v-on-clickaway="clickAway">
          <div class="presets hidden-xs">
            <ul v-if="presets">
              <li
                  v-for="(value, key) in presets"
                  :key="key"
                  :class="{ active: key === activePreset}"
                  @keypress="clickPreset(value)"
                  @click="clickPreset(value)">
                  <a class="btn btn-outline" :data-test="'cal_' + key" >{{key}}</a></li>
            </ul>
          </div>
          <div class="calendar left">
              <div class="calendar-table">
                  <calendar :monthDate="prevMonthDate" data-test="cal-left"
                            :locale="locale"
                            :start="start" :end="end"
                            @next-month="nextMonth" @prev-month="prevMonth"
                            @date-click="dateClick" @hover-date="hoverDate"
                  ></calendar>
              </div>
              <!-- <div class="date-range-picker-input hidden-xs">
                <date-input :value="start" @change="setStartDate" :format="format" />
              </div> -->
          </div>

          <div class="calendar right hidden-xs">
            <div class="calendar-table">
              <calendar :monthDate="monthDate" data-test="cal-right"
                        :locale="locale"
                        :start="start" :end="end"
                        @next-month="nextMonth" @prev-month="prevMonth"
                        @date-click="dateClick" @hover-date="hoverDate"
              ></calendar>
            </div>
            <!-- <div class="date-range-picker-input">
              <date-input :value="end" @change="setEndDate" :format="format" />
            </div> -->
          </div>
      </div>
    </transition>
  </div>
</template>

<script>

/* eslint-disable no-plusplus */

// Code from https://github.com/Innologica/vue2-daterange-picker

import { mixin as clickaway } from 'vue-clickaway';
import moment from 'moment';
// import FontAwesomeIcon from '@fortawesome/vue-fontawesome';

import Calendar from './DateRangePickerCalendar.vue';

// import CalendarPresets from './CalendarPresets';
import { nextMonth, prevMonth } from './util';

export default {
  components: { Calendar },
  mixins: [clickaway],
  props: {
    localeData: {
      type: Object,
      default() {
        return {};
      },
    },
    startDate: {
      default() {
        return new Date();
      },
    },
    endDate: {
      default() {
        return new Date();
      },
    },
    presets: {
      type: Object,
      default() {
        return {
          'Aujourd\'hui': [moment().startOf('day'), moment().endOf('day')],
          '7 derniers jours': [moment().add(-7, 'd').startOf('day'), moment().endOf('day')],
          '30 derniers jours': [moment().add(-30, 'd').startOf('day'), moment().endOf('day')],
          '90 derniers jours': [moment().add(-90, 'd').startOf('day'), moment().endOf('day')],
        };
      },
    },
    opens: {
      type: String,
      default: 'right',
    },
  },
  data() {
    const defaultLocale = {
      direction: 'ltr',
      format: moment.localeData().longDateFormat('L'),
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1, // moment.localeData().firstDayOfWeek()
    };

    // let data = { locale: _locale }
    const data = { locale: { ...defaultLocale, ...this.localeData } };
    data.format = 'DD/MM/YYYY';
    data.monthDate = new Date(this.startDate);
    data.start = new Date(this.startDate);
    data.end = new Date(this.endDate);
    data.inSelection = false;
    data.open = false;

    // update day names order to firstDay
    if (data.locale.firstDay !== 0) {
      let iterator = data.locale.firstDay;
      while (iterator > 0) {
        data.locale.daysOfWeek.push(data.locale.daysOfWeek.shift());
        iterator--;
      }
    }
    return data;
  },
  methods: {
    nextMonth() {
      this.monthDate = nextMonth(this.monthDate);
    },
    prevMonth() {
      this.monthDate = prevMonth(this.monthDate);
    },
    dateClick(value) {
      const dt = new Date(value);
      if (this.inSelection) {
        this.inSelection = false;
        if (value < this.initialDate) {
          this.start = dt;
          this.end = this.initialDate;
        } else {
          this.start = this.initialDate;
          this.end = dt;
        }
      } else {
        this.inSelection = true;
        this.initialDate = dt;
        this.start = dt;
        this.end = dt;
      }

      this.start = moment(this.start).startOf('day').toDate();
      this.end = moment(this.end).endOf('day').toDate();

      if (!this.inSelection) {
        return this.clickApply();
      }
      return null;
    },
    hoverDate(value) {
      const dt = new Date(value);
      if (this.inSelection) {
        if (dt > this.start) {
          this.start = this.initialDate;
          this.end = dt;
        } else if (dt < this.start) {
          this.end = this.initialDate;
          this.start = dt;
        }
      }
    },
    togglePicker() {
      this.open = !this.open;
    },
    pickerStyles() {
      return {
        'show-calendar': this.open,
        opensright: this.opens === 'right',
        opensleft: this.opens === 'left',
        openscenter: this.opens === 'center',
      };
    },
    clickCancel() {
      this.open = false;
    },
    clickApply() {
      this.open = false;
      this.$emit('update', { start: moment(this.start).utcOffset(0), end: moment(this.end).utcOffset(0) });
    },
    clickAway() {
      if (this.open) {
        this.clickApply();
      }
    },
    clickPreset(range) {
      this.setStartDate({ value: range[0].toDate() });
      this.setEndDate({ value: range[1].toDate() });
      this.clickApply();
    },

    setStartDate({ value }) {
      this.start = new Date(value);
      this.end = moment.max(moment(this.end), moment(value)).toDate();
    },
    setEndDate({ value }) {
      this.start = moment.min(moment(this.start), moment(value)).toDate();
      this.end = new Date(value);
      this.monthDate = this.end;
    },
  },
  computed: {
    prevMonthDate() {
      return prevMonth(this.monthDate);
    },
    startText() {
      return moment(this.start).format(this.format);
    },
    endText() {
      return moment(this.end).format(this.format);
    },
    label() { // →
      return this.activePreset || (this.startText === this.endText ? this.startText : `du ${this.startText} au ${this.endText}`);
    },
    activePreset() {
      const keys = Object.keys(this.presets);
      for (let i = 0; i < keys.length; i++) {
        const name = keys[i];
        const v = this.presets[name];
        if (v[0].isSame(this.start, 'day') && v[1].isSame(this.end, 'day')) {
          return name;
        }
      }
      return null;
    },
  },
  watch: {
    startDate(value) {
      this.setStartDate({ value });
    },
    endDate(value) {
      this.setEndDate({ value });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '@/styles/settings.scss';

  .date-range-picker {
    position: relative;
    display: inline-block;
    // &:hover {
    //   .reportrange-text {
    //     background-color: $primary;
    //     color: #fff;
    //   }
    // }
  }
  // .range_inputs {
  //     margin-bottom: 10px;
  // }

  .reportrange-text {
    text-align: center;
    cursor: pointer;
    padding: 5px 10px;
    // border: 1px solid #ccc;
    // border-radius: 0.2rem;
    width: 10em;
  }
  .reportrange-text-long span {
    font-size: 0.9em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;

  }
  .date-range-picker .show-calendar {
      display: inline-flex;
  }

  .date-range-picker .ranges {
      width: 160px;
  }

  .show-calendar {
      display: block;
      width: auto;
  }

  .calendar {
    padding: 0 1em;
    border-left: 2px solid #eee;

  }

  .date-range-picker-input {
    padding-top: 0.3em;
    input {
      font-size: 0.8em;
      text-align: center;
      border-radius: 0;
      padding: 0;
    }
  }
  .dropdown-menu {
    border-radius: 0.2rem;
    box-shadow: 0 1em 60px rgba(0,0,0,.2);
    background-color: #fff;

  }
  div.dropdown-menu.opensleft {
      top: 31px;
      right: 10px;
      left: auto;
  }

  div.dropdown-menu.openscenter {
      top: 31px;
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
  }

  div.dropdown-menu.opensright {
      top: 45px;
      left: 0;
      right: auto;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
      transition: all .2s ease;
  }

  .slide-fade-leave-active {
      transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active for <2.1.8 */
  {
      transform: translateY(-7px);
      opacity: 0;
  }

  .presets {
    width: 140px;
    ul {
      list-style: none;
      padding: 0;
    }
    a {
      display: block;
      text-align: left;
      padding: 0.1em 0.5em;
      border-radius: 0;
      font-size: 0.9em;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
    .active a {
      font-weight: 600;
      background-color: $primary;
      color:#fff;
      &:hover {
        color: #fff;
      }
    }
    .range-inputs {
      text-align: right;
      padding-right: .5em;
    }
  }
</style>
