import { render, staticRenderFns } from "./AuthReturn.vue?vue&type=template&id=362df6ff&scoped=true&"
import script from "./AuthReturn.vue?vue&type=script&lang=js&"
export * from "./AuthReturn.vue?vue&type=script&lang=js&"
import style0 from "./AuthReturn.vue?vue&type=style&index=0&id=362df6ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "362df6ff",
  null
  
)

export default component.exports