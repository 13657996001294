module.exports = {
  INIT: 'Init',
  SENT: 'Demande Envoyée',
  ABORT: 'Demande annulée',
  OPENED: 'Mail ouvert',
  PAYMENT_STARTED: 'En cours',
  PAYMENT_CANCELLED: 'Paiement annulé',
  PAYMENT_ACCEPTED: 'À valider',
  PAYMENT_PENDING: 'En attente',
  PAYMENT_PENDING_RISK: 'Risque de Fraude',
  PAYMENT_COMPLETE: 'Payé',
  PAYMENT_REFUSED: 'Paiement refusé',
  PAYMENT_ERROR: 'Echec paiement',
  EXPIRED: 'Paiement expiré',
};
