<template>
  <div class="notifications" v-if="list.length" data-test="notifications" >
    <div class="card"
      v-for="(item,i) in list"
      :key="i"
      :class="{
        'bg-warning text-white': item.type === 'warn',
        'bg-danger text-white': item.type === 'error',
        'bg-success text-white': item.type === 'success' }"
      >
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h5 class="card-title" v-if="item.title">{{item.title}}</h5>
          <span>
            <a href="#" class="btn btn-sm btn-outline-light"
              @click="drop(item)">&times;</a>
          </span>
        </div>
        <p class="card-text" v-if="item.message">{{item.message}}</p>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Notifications',
  methods: {
    ...mapActions('notifications', {
      drop: 'drop',
    }),
  },
  computed: {
    ...mapGetters('notifications', {
      list: 'list',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import '../styles/settings.scss';
.notifications {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1em;
  z-index: 10000;
}

.card {
  margin-bottom: 1em;
  min-width: 20em;
  box-shadow: 0 10px 30px rgba(#000, .2);
  font-size: 0.8em;
  .card-title {
    font-size: 1.4em;
  }
}
.card-body {
  padding: 1em;
}
</style>
