<template>
  <div class="pt-2">
    <div class="mt-5 mb-4 list-heading">
      <h1 class="page-title">Liste des demandes</h1>
      <span>
        <button
          @click="onClickAskPayment" data-test="b-add"
          class="btn btn-large btn-warning"
          v-if="merchant.id"
        >
          Demander un paiement
        </button>
      </span>
    </div>
    <div class="search-bar d-flex align-items-stretch">
      <date-range-picker
        :startDate="transactionListFromDate"
        :endDate="transactionListToDate"
        @update="onDateChange"
         />
      <ComboStatus @change="setSearchStatus" v-model="transactionListStatus" />
      <input type="search" class="form-control" @input="setSearch"
       v-model="transactionListSearch_ro"
      placeholder="Rechercher..." data-test="search" />
      <button
          @click="loadTransactionList" data-test="bt-search"
          class="btn btn-sm btn-outline-primary btn-reload"
        >
          <span :class="{'spin': isListLoading}">↻</span>
        </button>
    </div>
    <div class="table-wrapper">
      <!-- <loader v-if="isListLoading" /> -->
      <table class="table table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Date</th>
            <th>Email</th>
            <th>Vendeur</th>
            <th>Référence</th>
            <th>Montant</th>
            <th>Statut</th>
            <th style="min-width: 120px">Actions</th>
          </tr>
        </thead>
        <tbody v-if="isListLoading" class="loading">
          <tr>
            <td colspan="7">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="transactionList && transactionList.length > 0">
          <tr
            v-for="(transaction, i) in transactionList"
            :key="i"
            :class="{'is-muted': transaction.isTerminated }" :data-test="'tr_' + i"
            @click="onClickEdit(transaction.id)">
            <td>{{formatDatetime(transaction.created_at) }}</td>
            <td :style="{'font-size': transaction.email.length > 30 ? '.65em': '.8em'}">
              {{transaction.email}}
            </td>
            <td>{{transaction.seller}}</td>
            <td>{{transaction.ref}}</td>
            <td>{{formatCurrency(transaction.amount) }}</td>
            <td>
              <transaction-status
                :status="transaction.status"
                :statusDates="transaction.status_dates"
              />
            </td>
            <td @click.stop>
              <button-confirm
                v-if="transaction.status === 'PAYMENT_ACCEPTED'"
                @click="onClickValidate(transaction.id)"
                :disabled="isCaptureLoading || !permissions['EPM.pr.write']"
                confirmMessage="Confirmer"
                class="btn btn-success btn-sm" title="Valider le paiement">
                <span
                  v-if="isCaptureLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
                <span v-else>Valider</span>
              </button-confirm>
              <button
                v-else-if="!transaction.isUpdatable"
                class="btn btn-info btn-sm"
                @click="onClickEdit(transaction.id)">Voir</button>
              <button
                v-else
                class="btn btn-primary btn-sm"
                @click="onClickEdit(transaction.id)">Editer</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center text-muted">Aucune transaction trouvée</td>
          </tr>
        </tbody>
      </table>
      <paginator />
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';
import TransactionStatus from '../components/TransactionStatus.vue';
import Paginator from '../components/Paginator.vue';
import ButtonConfirm from '../components/ButtonConfirm.vue';
import DateRangePicker from '../components/DateRangePicker/DateRangePicker.vue';
import ComboStatus from '../components/ComboStatus.vue';

import { currency } from '../filters/currency';
import { datetime } from '../filters/date';

let setSearchTimeout = null;
export default {
  components: {
    TransactionStatus,
    Paginator,
    ButtonConfirm,
    DateRangePicker,
    ComboStatus,
  },
  methods: {
    ...mapActions([
      'loadTransactionList',
      'captureTransaction',
    ]),
    ...mapMutations([
      'setTransactionListDate',
      'setTransactionListSearch',
      'setTransactionListStatus',
    ]),
    onClickEdit(id) {
      this.$router.push({ name: 'transaction-edit', params: { id } });
    },
    onClickAskPayment() {
      this.$router.push({ name: 'transaction-new' });
    },
    onDateChange({ start, end }) {
      this.setTransactionListDate({
        start, end,
      });
      this.loadTransactionList();
    },
    async onClickValidate(id) {
      await this.captureTransaction(id);
      await this.loadTransactionList();
    },
    setSearch(e) {
      this.setTransactionListSearch(e.target.value);
      clearTimeout(setSearchTimeout);
      setSearchTimeout = setTimeout(() => {
        this.loadTransactionList();
      }, 800);
    },
    setSearchStatus(e) {
      this.setTransactionListStatus(e);
      clearTimeout(setSearchTimeout);
      setSearchTimeout = setTimeout(() => {
        this.loadTransactionList();
      }, 800);
    },
    onEnter() {
      this.loadTransactionList();
    },
    formatCurrency(e) {
      return currency(e);
    },
    formatDatetime(e) {
      return datetime(e);
    },
  },
  computed: {
    ...mapGetters([
      'isListLoading',
      'isCaptureLoading',
    ]),
    ...mapState([
      'transactionListFromDate',
      'transactionListToDate',
      'transactionListSearch',
      'transactionListStatus',
      'permissions',
      'merchant',
    ]),
    transactionListSearch_ro: {
      get() {
        return this.transactionListSearch;
      },
      set() {
      },
    },
    transactionList: {
      get() {
        return this.$store.state.transactionList;
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onEnter(to.params);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/settings";
.status {
  font-size: 0.7em;
}

.btn-reload {
  min-width: 3em;
  span {
    display: inline-block;
  }
  span.spin {
    animation: rotating 1s linear infinite;
  }
}

@keyframes rotating {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.list-heading {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;

  input[type=search] {
    display: inline-block;
    width: auto;
    margin-right: 1em;
  }
}

.search-bar {
  &>* {
    margin: 0.5em;
  }
}
.table-wrapper {
  position: relative;
  min-height: 15em;
}

.table {
  background-color: white;
  box-shadow: 0 0 20px rgba(black, .15);
  border-radius: 4px;

  tr.is-muted {
    color: $gray-500;
    font-style: italic;
  }

  td {
    text-align: center;
    font-size: .8em;
    vertical-align: baseline;
  }

  tbody.loading td .spinner-border {
    margin: 3em;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba($orange, 0.1);
}

.table-hover tbody tr:hover {
  background-color: rgba($primary, 0.1);
}

.table .thead-dark th {
  color: white;
  background-color: $primary;
  border: 0;
  text-transform: uppercase;
  font-size: .8em;
  text-align: center;

  &:first-child {
    border-radius: 4px 0 0 0;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
  }
}

</style>
