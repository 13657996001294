import transactionStatus from '../lib/transactionStatus';

function statusName(value) {
  if (value) {
    return transactionStatus[value];
  }
  return '';
}

function statusClass(value) {
  if (value) {
    return `status-${value.toLowerCase().replace('_', '-')}`;
  }
  return '';
}

export { statusClass, statusName };
