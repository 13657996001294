<template>
  <div class="loader-overlay" data-test="loader" >
    <div class="d-flex justify-content-center mt-5">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {

};
</script>

<style lang="scss" scoped>
.loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.3);
  z-index: 2;
}
</style>
