/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-shadow */

import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import api from '../lib/api';
import user from './user';
import notifications from './notifications';

Vue.use(Vuex);

let delayedPreviewTs = null;
let prevPreviewPayloadKey = null;
function delayedPreview(transaction, commit) {
  if (delayedPreviewTs) {
    clearTimeout(delayedPreviewTs);
  }
  const key = JSON.stringify(transaction);
  delayedPreviewTs = setTimeout(async () => {
    if (key === prevPreviewPayloadKey) {
      return;
    }
    prevPreviewPayloadKey = key;
    delayedPreviewTs = null;
    const {
      data,
    } = await api.name('preview').post('/preview', transaction);
    commit('setPreview', data);
  }, 500);
}

const state = {
  // merchantName: localStorage.getItem('merchant_name') || '',
  merchant: {},
  // "EPM.pr.write", "EPM.pr.read", ...
  permissions: {},
  merchants: [],
  defaultTransaction: {
    seller: '',
    email: '',
    amount: '',
    capture: null,
    ref: '',
    description: '',
    message: '',
    isUpdatable: true,
  },
  preview: null,
  transaction: {},
  transactionListFromDate: moment().add(-30, 'days').startOf('day').toDate(),
  transactionListToDate: moment().endOf('day').toDate(),
  transactionListSearch: '',
  transactionListStatus: '',
  transactionList: [],
  transactionListPageSize: 10,
  transactionListCurrentPage: 1,
  loading: {
    merchant: 0,
    capture: 0,
    preview: 0,
    get: 0,
    list: 0,
    save: 0,
  },
};

const mutations = {

  setMerchant(state, merchant) {
    state.merchant = merchant;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  setMerchants(state, merchant) {
    state.merchants = merchant;
  },
  setTransaction(state, value) {
    if (value) {
      // eslint-disable-next-line no-param-reassign
      value.amount = +value.amount;
    }
    if (!value.contracts) {
      // eslint-disable-next-line no-param-reassign
      value.contracts = [];
    }
    state.transaction = value;
    // state.preview = null; // Invalidate preview
  },
  setPreview(state, value) {
    state.preview = value;
    if (!value) {
      // preview blank
      // when user clicks on
      // new demand, return, new demand
      prevPreviewPayloadKey = null;
    }
  },
  setTransactionListPage(state, page) {
    state.transactionListCurrentPage = +page || 1;
  },
  setTransactionListDate(state, { start, end }) {
    state.transactionListFromDate = start;
    state.transactionListToDate = end;
  },
  setTransactionListSearch(state, search) {
    state.transactionListSearch = search;
  },
  setTransactionListStatus(state, status) {
    state.transactionListStatus = status;
  },
  setTransactionList(state, { items }) {
    state.transactionList = items;
    state.transaction = {};
  },
  setLoading(state, { name, loading }) {
    state.loading[name] += loading ? 1 : -1;
  },
};

const actions = {

  signOutActions({ commit }) {
    commit('setMerchants', []);
    commit('setMerchant', {});
    commit('SET_PERMISSIONS', {});
  },

  async setPermissions({ commit, state, dispatch }) {
    const { pByBundleId, bundlesByMerchantId } = state.user.habilitation;
    const bundleIds = Object.keys(pByBundleId)
      .filter((id) => pByBundleId[id].find((p) => p.key === 'EPM.pr.read'))
      .map((e) => +e);
    const merchants = Object.keys(bundlesByMerchantId)
      .filter((id) => bundlesByMerchantId[id].bundlesIds.some((e) => bundleIds.includes(e)))
      .map((id) => (
        { id, name: bundlesByMerchantId[id].name }))
      .filter((m) => m.name);

    commit('setMerchants', merchants);
    if (merchants.length > 0) {
      await dispatch('setMerchant', merchants[0].id);
    }
  },

  async setMerchant({ commit, dispatch }, id) {
    const { data } = await api.name('merchant').get(`/merchant/${id}`);
    commit('setMerchant', data);
    const { pByBundleId, bundlesByMerchantId } = state.user.habilitation;
    const permissions = bundlesByMerchantId[id].bundlesIds
      .map((bundleId) => pByBundleId[bundleId].map((e) => e.key));
    const merged = [].concat([], ...permissions)
      .reduce((acc, e) => {
        acc[e] = true;
        return acc;
      }, {});
    commit('SET_PERMISSIONS', merged);
    await dispatch('loadTransactionList');
  },
  async loadTransactionList({ state, commit }, page = 1) {
    if (state.merchant && state.merchant.id) {
      commit('setTransactionListPage', page);
      const { data } = await api.name('list').get('/', {
        params: {
          pageSize: state.transactionListPageSize,
          page: state.transactionListCurrentPage,
          fromDate: +state.transactionListFromDate,
          toDate: +state.transactionListToDate,
          search: state.transactionListSearch,
          status: state.transactionListStatus,
          merchant: state.merchant.id,
        },
      });
      commit('setTransactionList', data);
    }
  },

  async newTransaction({ state, commit, dispatch }) {
    const tr = { ...state.defaultTransaction };
    const { settings, payline } = state.merchant;
    tr.capture = settings && 'capture' in settings ? settings.capture : tr.capture;
    tr.legal = settings && settings.legal || tr.legal;
    tr.description = settings && settings.description || tr.description;
    tr.message = settings && settings.message || tr.message;
    if (settings && settings.expirations_presets && settings.expirations_presets.length > 0) {
      tr.expires_in = settings && settings.expirations_presets[0].value;
    }
    if (payline) {
      tr.contracts = payline.contract_numbers.map((e) => ({ ...e, selected: payline.contract_numbers.length === 1 }));
    }

    commit('setTransaction', tr);
    commit('setPreview', null);
    dispatch('updatePreview');
  },

  async loadTransaction({ dispatch, commit }, transactionId) {
    commit('setTransaction', {}); // Reset
    commit('setPreview', null);
    const tr = await api.name('get').get(`/${transactionId}`);
    const { payline } = state.merchant;
    if (tr.data.contract_numbers) {
      const list = tr.data.contract_numbers;
      tr.data.contracts = payline.contract_numbers.map((e) => ({
        ...e,
        selected: list.some((a) => a.name === e.name),
      }));
    } else {
      tr.data.contracts = payline.contract_numbers.map((e) => ({ ...e, selected: true }));
    }
    dispatch('setTransaction', tr.data);
  },

  async setTransaction({ commit, dispatch }, transaction) {
    commit('setTransaction', transaction);
    dispatch('updatePreview');
  },

  async deleteTransaction(transactionId) {
    await api.delete(`/${transactionId}`);
  },

  async saveTransaction({ state, dispatch }, editMode = false) {
    const tr = { ...state.transaction };
    tr.merchant = state.merchant.id;
    tr.contract_numbers = tr.contracts.filter((e) => e.selected);
    if (editMode) {
      const { status } = await api.name('save').put(`/${state.transaction.id}`, tr);
      if (status === 200) {
        dispatch('notifications/add', {
          type: 'success',
          title: 'Demande renvoyée',
          message: 'La transaction a bien été sauvegardée et envoyée au client',
        });
      }
    } else {
      const { status } = await api.name('save').post('/', tr);
      if (status === 200) {
        dispatch('notifications/add', {
          type: 'success',
          title: 'Demande envoyée',
          message: 'La transaction a bien été sauvegardé et envoyée au client',
        });
      }
    }
    dispatch('loadTransactionList');
  },
  async updatePreview({ commit, state }) {
    delayedPreview({
      ...state.transaction,
      merchant: state.merchant.id,
    }, commit);
  },
  async captureTransaction({ dispatch }, transactionId) {
    const { status } = await api.name('capture').post(`/${transactionId}/capture`, {});
    if (status === 200) {
      dispatch('notifications/add', {
        type: 'success',
        title: 'Transaction validée',
        message: 'La transaction a bien été validée',
      });
    }
  },

};

const getters = {
  isPreviewLoading(state) {
    return state.loading.preview > 0;
  },
  isListLoading(state) {
    return state.loading.list > 0;
  },
  isSaveLoading(state) {
    return state.loading.save > 0;
  },
  isCaptureLoading(state) {
    return state.loading.capture > 0;
  },
  isGetLoading(state) {
    return state.loading.get > 0;
  },
  isMerchantLoading(state) {
    return state.loading.merchant > 0;
  },

};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    notifications,
    user,
  },
});
