<template>
  <div
    @mouseenter="setVisible"
    @mouseleave="setVisible"
    @focus="setVisible"
    @focusout="setVisible"
    class="status-badge"
    :class="formatStatusClass(status )"
  >
    <span :class="formatStatusClass(status )"
     class="fw-bold">{{ formatStatusName(status ) }}</span>
    <div class="status-history" v-show="isVisible">
      <ul>
        <li class="status-item" v-for="(item,i) in dates" :key="i">
          <div v-if="!item.status" class="date">{{formatDate(item.date )}}</div>
          <div v-else class="status d-flex  justify-content-start">
            <span class="time">{{formatTime(item.date )}}</span>
            <span>
              <span class="status-text"
                :class="formatStatusClass(item.status )">
                {{ formatStatusName(item.status ) }}
              </span>
              <span class="seller-text" v-if="item.status === 'SENT'">
                par {{ item.seller }}
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import * as FormatStatus from '../filters/status';
import { date, time } from '../filters/date';

export default {
  props: {
    status: String,
    statusDates: Array,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    setVisible() {
      if (Object.keys(this.statusDates).length > 0) {
        this.isVisible = !this.isVisible;
      }
    },
    formatStatusName(e) {
      return FormatStatus.statusName(e);
    },
    formatStatusClass(e) {
      return FormatStatus.statusClass(e);
    },
    formatDate(e) {
      return date(e);
    },
    formatTime(e) {
      return time(e);
    },
  },
  computed: {
    dates() {
      let curDate;
      return (this.statusDates || [])
        // eslint-disable-next-line no-nested-ternary
        .sort(({ date: a }, { date: b }) => (a === b ? 0 : a < b ? 1 : -1))
        .reduce((a, item) => {
          const dt = moment(item.date).format('YYYYMMDD');
          if (dt !== curDate) {
            // eslint-disable-next-line no-param-reassign
            item.dateChanged = true;
            a.push({ date: item.date });
          }
          curDate = dt;
          a.push(item);
          return a;
        }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/settings";
.status-badge {
  font-size: .8em;
  font-style: normal;
  position: relative;
  display: inline-block;
  padding: 2px 12px;
  border-radius: 4px;
  border: solid 1px transparent;
  &:hover {
    transition: all ease-in .2s;
    cursor: pointer;
  }

  .status-history {
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0,0,0, .15);
    color: $gray-800;
    min-width: 260px;
    padding: 1em;
    position: absolute;
    right: 50%;
    text-align: left;
    top: 2em;
    transform: translateX(50%);
    z-index: 1030;

    &::before {
      border: solid 5px transparent;
      border-bottom-color: white;
      content: '';
      height: 1px;
      left: 50%;
      position: absolute;
      top: -10px;
      transform: translateX(-50%);
      width: 1px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

  }
}

.status-item {
  display: flex;
  justify-content: space-between;
  font-size: .8rem;
  em {
    font-weight: 100;
    display: block;
  }

  .date {
    color: $gray-800;
    margin-top: .3em;
  }
  .time {
    color: $gray-600;
  }
  .time+span {
    margin-left: .5em;
    padding-left: .5em;
    border-left: 1px solid $gray-200;
  }
  .status {
    margin-left: .5em;
  }

  .status-text {
    font-weight: bold;
  }
  .seller-text {
    display: block;
    font-style: italic;
    line-height: .9em;
    font-size: .8em;
    opacity: .8;
  }

  &:hover {
    .date {
      color: $gray-900;
    }
    .time {
      color: $gray-800;
    }
  }
}
.status-item:first-child .date {
  margin-top: 0;
}

@each $key, $value in $status-colors {
  .status-#{$key} { color: $value; }
  .status-badge {
    &.status-#{$key} {
      background: rgba($value, .1);
      border-color: rgba($value, .3);
    }
  }
}
</style>
