const {
  VUE_APP_AWS_CLIENT_DOMAIN, VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID, VUE_APP_HABILITATION_URL,
  VUE_APP_EPM_ADMIN_ENDPOINT, VUE_APP_HELP_URL,
  VUE_APP_AWS_CLIENT_DOMAIN_HL, VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID_HL,
  VUE_APP_HABILITATION_URL_HL, VUE_APP_EPM_ADMIN_ENDPOINT_HL, VUE_APP_HELP_URL_HL
} = process.env;

const context = new Map();
context.set(VUE_APP_AWS_CLIENT_DOMAIN, {
  userPoolWebClientId: VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  hiFront: VUE_APP_HABILITATION_URL,
  epmAdminFront: VUE_APP_EPM_ADMIN_ENDPOINT,
  helpUrl: VUE_APP_HELP_URL
});
context.set(VUE_APP_AWS_CLIENT_DOMAIN_HL, {
  userPoolWebClientId: VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID_HL,
  hiFront: VUE_APP_HABILITATION_URL_HL,
  epmAdminFront: VUE_APP_EPM_ADMIN_ENDPOINT_HL,
  helpUrl: VUE_APP_HELP_URL_HL
});

export default {
  get(type) {
    const urls = context.get(window.location.origin);
    if (urls) {
      return urls[type];
    }
    console.warn(`${window.location.origin} not found. Existing: ${[...context.keys()]}`);
    if (localStorage.getItem('cy-client') === 'HL') {
      return context.get(VUE_APP_AWS_CLIENT_DOMAIN_HL)[type];
    }
    return context.get(VUE_APP_AWS_CLIENT_DOMAIN)[type];
  },
};
